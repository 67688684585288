import "./import/modules";
import "./import/components";

$(function() {
	$('.header_burger').on('click touch', function(){
		if($('.mobile__menu').hasClass('active')){
			$('.mobile__menu').removeClass('active');
		}
		else {
			$('.mobile__menu').addClass('active');
		}
	});
});